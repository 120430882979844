<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="Name">
                          <span class="required">*</span> Titulo:
                        </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="process.title"
                          placeholder=""
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="Name">
                          <span class="required">*</span> Número do Processo:
                        </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="process.number"
                          placeholder=""
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-12">
                        <label>
                          <span class="required">*</span> Descrição:
                        </label>
                        <textarea class="form-control" v-model="process.description" cols="30" rows="5" disabled></textarea>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="Name">
                          <span class="required">*</span> Responsável:
                        </label>
                        
                        <v-select :options="users" :reduce="(users) => users.id" :label="'name'" @input="changeResponsible"
                          v-model="process.responsible_id" class="vselect" 
                        />
                      </div>
                      
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <label>Progresso do Processo</label>
                        <div class="progress" style="height: 30px;">
                            <div class="progress-bar progress-bar-striped" role="progressbar" :style="'width:' + process.perc +'%;'" :aria-valuenow="process.perc" aria-valuemin="0" aria-valuemax="100">
                                {{ process.perc }}%
                            </div>
                        </div>
                      </div>
                    </div>
                    

                    <div class="row">
                        <div class="col-md-12 bold mt-2" style="font-size: 18px">
                            Recortes deste processo
                        </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-md-6">
                        <button class="btn btn-success" @click="markAll">
                          Marcar todos como Concluído
                        </button>
                      </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-md-12 mt-3" v-for="clipping in process.clippings" :key="clipping.id">
                            <div class="card0">
                                <div class="card0-header">
                                    Encontrado em: {{ clipping.created_at }} 
                                    <span class="ml-2 badge badge-danger" v-if="clipping.status == 0">Não despachado</span> 
                                    <span class="ml-2 badge badge-warning" v-if="!clipping.responsible_id">Sem responsável atribuído</span>
                                    <span class="ml-2 badge badge-info" v-if="clipping.status == 1">Aguardando atuação</span>
                                    <span class="ml-2 badge badge-success" v-if="clipping.status == 2">Finalizado</span>
                                </div>
                                <div class="card-body">
                                    <div class="row" v-if="clipping.responsible">
                                      <div class="col-md-12">
                                        <div class="alert alert-primary p-2" role="alert">
                                          <span>Responsável: {{ clipping.responsible.name }}</span> <br>
                                          <span v-if="clipping.closed_at">Finalizado em: {{ clipping.closed_at }} <br></span> 
                                          <span v-if="clipping.closed">Finalizado por: {{ clipping.closed.name }}</span>
                                        </div>
                                        
                                      </div>
                                    </div>
                                    <h5 class="card-title">{{ clipping.title }}</h5>
                                    <p class="card-text" v-html="clipping.description"></p>
                                    <button class="btn btn-primary" @click="sendMail(clipping)"  v-if="clipping.status == 0">Enviar E-mail</button>
                                    <button class="btn btn-primary" @click="resendMail(clipping)"  v-if="clipping.status == 1">Reenviar E-mail</button>
                                    <button class="ml-2 btn btn-success" @click="closeClipping(clipping)"  v-if="clipping.status == 1">Marcar como concluído</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="block text-right">
                      <router-link to="/processes" class="btn btn-back">
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                      
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Atribuir Responsável -->
    <div class="modal fade" id="responsibleModalCenter" tabindex="-1" role="dialog" aria-labelledby="responsibleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="responsibleModalLongTitle">Atribuir responsável para este recorte</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">       
                <div class="alert alert-info p-2" role="alert">
                  Ao atribuir esta tarefa para um usuário, ele receberá um e-mail com os dados deste recorte.
                </div>
              </div>
              <div class="col-md-12">
                <label>Selecione um usuário</label>                
                <v-select :options="users" :reduce="(users) => users.id" :label="'name'"
                  v-model="clipping_selected.responsible_id" class="vselect" />
              </div>
            </div>     
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-primary" @click="resendMail(clipping_selected)">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      users: [],
      clipping_selected: {},
      process: {
        title: '',
        description: '',
        responsible_id: '',
      },      
      errors: undefined,
    };
  },
  computed: {},
  methods: {
    toggleFormSenha() {
      const self = this;
      if (self.change_password) {
        self.change_password = false;
      } else {
        self.change_password = true;
      }
    },
    
    save: function () {
      const self = this;
      let api = self.$store.state.api + "process/change-responsible";
      
      axios
        .post(api, self.process)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.getProcess(self.process.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    changeResponsible(){
      const self = this;

      swal({
        title: "Deseja alterar o responsável?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, alterar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "O responsável não foi alterado!", "info");
            self.process.responsible_id = null;
            break;

          case "confirm":
            self.save();
            break;
        }
      });
    },
    getProcess: function (id) {
      const self = this;
      const api = self.$store.state.api + "processes/" + id + '?with[]=clippings';

      axios
        .get(api)
        .then((response) => {
          self.process = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUsers: function () {
      const self = this;
      const api = self.$store.state.api + "users?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.users = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    sendMail(clipping){
      const self = this;
      self.clipping_selected = clipping;

      $('#responsibleModalCenter').modal('show');
    },
    resendMail: function (obj) {
      const self = this;
      const api = self.$store.state.api + "process_clipping/resend";

      axios
        .post(api, obj)
        .then((response) => {
          self.$message('Sucesso!', 'E-mail reenviado', "success");
          self.getProcess(self.process.id);
          $('#responsibleModalCenter').modal('hide');
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          $('#responsibleModalCenter').modal('hide');
        });
    },
    markAll(){
      const self = this;
      const api = self.$store.state.api + "process_clipping/close-all";

      axios
        .post(api, self.process)
        .then((response) => {
          self.$message('Sucesso!', 'Recortes finalizados', "success");
          self.getProcess(self.process.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          $('#responsibleModalCenter').modal('hide');
        });
    },
    closeClipping: function (obj) {
      const self = this;
      const api = self.$store.state.api + "process_clipping/close";

      axios
        .post(api, obj)
        .then((response) => {
          self.$message('Sucesso!', 'Recorte finalizado', "success");
          self.getProcess(self.process.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          $('#responsibleModalCenter').modal('hide');
        });
    }
  },
  mounted: function () {
    const self = this;

    self.getUsers();

    let id = self.$route.params.id;
    if (id) {
      self.getProcess(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}

.card0 {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

.card0-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.card0-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

pre{
    background-color: white;
}

.formatted-text {
  white-space: pre-wrap; /* Permite quebras de linha */
  word-wrap: break-word; /* Garante que palavras longas sejam quebradas */
  overflow-wrap: break-word; /* Alternativa moderna ao word-wrap */
}
</style>