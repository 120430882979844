<template>
  <div>
    <div class="row">
      <div class="col-md-3 mb-2" v-if="loading">
        <div class="row mt-1">
          <div class="col-md-2">
            <toggle-button
                v-model="status"
                color="#15488A"
                :height="25"
                @change="setValue"
            />
          </div>
          <div class="col-md-10">Exibir finalizados</div>
        </div>
        <div class="row mt-1">
          <div class="col-md-2">
            <toggle-button
                v-model="status2"
                color="#15488A"
                :height="25"
                @change="setValue2"
            />
          </div>
          <div class="col-md-10">Exibir somente finalizados</div>
        </div>
      </div>
      <div class="col-md-12">
        <base-crud
          :ref="'processCrud'"
          crudName="Processos"
          newText="Novo"
          :selectedName="user.access_nivel == 1 ? 'Enviar Processos' : null"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="true"
          :enableEdit="user.access_nivel == 1 ? true : false"
          :enableDelete="true"
          :enableView="true"
          :enableReset="false"
          :dataForm="dataForm"
          @checkeds="checkeds"
        >
        </base-crud>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="modalUsers" tabindex="-1" role="dialog" aria-labelledby="modalUsersLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalUsersLabel">Selecione o usuário que receberá os processos</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <label for="Name">
                  <span class="required">*</span> Responsável:
                </label>
                
                <v-select :options="users" :reduce="(users) => users.id" :label="'name'"
                  v-model="process.responsible_id" class="vselect" 
                />
              </div>
              <div class="col-md-6">
                <label for="Name">
                  <span class="required">*</span> Prioridade:
                </label>                
                <v-select :options="priorities" :reduce="(priorities) => priorities.id" :label="'name'"
                  v-model="process.priority" class="vselect" 
                />
              </div>
              <div class="col-md-6">
                <label for="Name">
                  <span class="required">*</span> Prazo:
                </label>                
                <input type="datetime-local" class="form-control" v-model="process.deadline" id="">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary" @click="saveResponsible">Salvar Alterações</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      user: {
        access_nivel: 0,
      },
      loading: true,
      status: false,
      status2: false,
      filter_status: 'status=1',
      table: "usersTable",
      showMessageImport: false,
      processCrud: {},
      process: {},
      users: [],
      priorities: [
        {
          id: 3,
          name: 'Alta'
        },
        {
          id: 2,
          name: 'Média'
        },
        {
          id: 1,
          name: 'Baixa'
        },
      ],
      pages: [],
      url: "",
      columns: ['checkbox', "id", "number", "clippings", 'responsible_id', 'status', 'priority', 'deadline', "actions"],
      tableData: ["id", "number", "clippings", 'responsible_id', 'priority', 'deadline', 'status'],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["number", 'responsible_id', "clippings"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          checkbox: "",
          id: "ID",
          number: "Número",
          clippings: "Recortes",
          responsible_id: "Responsável",
          priority: "Urgência",
          deadline: "Prazo",
          actions: "Ações",
        },

        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },   
        listColumns: {
          responsible_id: [],
        },     
        templates: {
          status: function (h, row, index) {
            if(row.status == 1){
              return (
                <h5 class="text-center">
                  <span class="ml-2 badge badge-info">
                    Ativo
                  </span>
                </h5>
              );
            } else {
              return  (
                <h5 class="text-center">
                  <span class="ml-2 badge badge-success">
                    Finalizado
                  </span>
                </h5>
              );
            }
          },
          priority: function (h, row, index) {
            if(row.priority == 1){
              return (
                <h5 class="text-center">
                  <span class="ml-2 badge badge-success">
                    Baixa
                  </span>
                </h5>
              );
            } else if(row.priority == 2) {
              return  (
                <h5 class="text-center">
                  <span class="ml-2 badge badge-warning">
                    Média
                  </span>
                </h5>
              );
            } else if(row.priority == 3) {
              return  (
                <h5 class="text-center">
                  <span class="ml-2 badge badge-danger">
                    Alta
                  </span>
                </h5>
              );
            }  else {
              return  (
                ''
              );
            }
          },
          responsible_id: function (h, row, index) {
            return row.responsible ? row.responsible.name
              : 'Não atribuído';
          },
          clippings: function (h, row, index) {
            if(row.pending_clippings == true || row.pending_clippings == 'true'){
              return (
                <h5 class="text-center">
                  {row.clippings.length} <span class="ml-2 badge badge-warning">
                    Pendências
                  </span>
                </h5>
              );
            } else {
              return  (
                <h5 class="text-center"> { row.clippings.length } 
                  <span class="ml-2 badge badge-success">
                    OK
                  </span>
                </h5>
              );
            }
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          console.log(this.$parent.$parent);

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = ['responsible', 'clippings'];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "processes",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    setValue(){
      const self = this;

      if(self.status == true){
        self.filter_status = '';
      } else {
        self.filter_status = 'status=1';
      }

      this.$refs.processCrud.$refs.table.refresh()
    },
    setValue2(obj){
      const self = this;

      if(obj.value == false){
        self.filter_status = 'status=1';
        self.status = false;
      } else {
        self.filter_status = 'status=2';
        self.status = false;
      }

      self.loading = false;
      setTimeout(() => {
        self.loading = true;  
      }, 200);

      this.$refs.processCrud.$refs.table.refresh()
    },
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let fileImport = document.getElementById("fileImport");

      fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

      return fd;
    },

    query: function (query) {
      let columns = {
        id: "id",
        number: "number",
        responsible_id: "responsible[responsible_id]"
      };
      let filters = "" + this.filter_status;
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },

    checkeds(data){      
      $('#modalUsers').modal('show');
      this.process.selecteds = data;
    },

    saveResponsible(){
      const self = this;
      const api = self.$store.state.api + "process/save-in-mass";

      axios
        .post(api, self.process)
        .then((response) => {
          $('#modalUsers').modal('hide');   
          this.$refs.processCrud.$refs.table.refresh()
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getUsers: function () {
      const self = this;
      const api = self.$store.state.api + "users?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.users = response.data.data;

          var arr = [];

          self.users.forEach(element => {
            arr.push({id: element.id, text: element.name})  
          });

          self.options.listColumns.responsible_id = arr;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted(){
    this.getUsers();  
    const self = this;
    // console.log(this.$refs.processCrud.$refs.table.refresh())

    setTimeout(() => {
      self.user = self.$store.state.user;
    }, 1000);
  }
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>